import React, { useState } from "react";
// import {useSelector, useDispatch} from 'redux'
import { Redirect } from "react-router-dom";
import "../Admin.css";
import axios from "axios";

const CreateConference = () => {
  const [confName, setConfName] = useState("");
  const [location, setLocation] = useState("");
  const [date, setDate] = useState("");
  const [website, setWebsite] = useState("");
  const [created, setCreated] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const conference = {
        confName,
      location,
      date,
      website,
    };

    axios
      .post("/createConference", { conference })
      .then((response) => alert(response.data.msg))
      .then(setCreated(true));
  };

  let pageDisplay = "";
  if (!created) {
    pageDisplay = (
      <div className="formContainer">
        <h1 className="formHeading">Enter Conference Details</h1>
        <form onSubmit={handleSubmit} className="form">
          <div className="labelInput">
            <label htmlFor="confName" className="formLabel">
              Conference Name
            </label>
            <input
              type="text"
              id="confName"
              name="confName"
              value={confName}
              onChange={(evt) => setConfName(evt.target.value)}
              className="formInput"
              placeholder="Conference Name"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="location" className="formLabel">
              Location
            </label>
            <input
              type="text"
              id="location"
              name="location"
              value={location}
              onChange={(evt) => setLocation(evt.target.value)}
              className="formInput"
              placeholder="Location"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="date" className="formLabel">
            Date
            </label>
            <input
              type="text"
              id="date"
              name="date"
              value={date}
              onChange={(evt) => setDate(evt.target.value)}
              className="formInput"
              placeholder="Date"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="website" className="formLabel">
              Website
            </label>
            <input
              type="text"
              id="website"
              name="homepage"
              value={website}
              onChange={(evt) => setWebsite(evt.target.value)}
              className="formInput"
              placeholder="Website"
            />
          </div>
          <input className="submitButton" type="submit" value="Submit" />
        </form>
      </div>
    );
  } else {
    pageDisplay = <Redirect to="/" />;
  }

  return pageDisplay;
};

export default CreateConference;
