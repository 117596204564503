import axios from "axios";

export const GET_TIMES = "GET_TIMES";

export const getTimes = () => {
  return async (dispatch) => {
    let evtArray = [];
    let allEvents = [];
    axios.get("/events").then((response) => {
      const allData = response.data.events;
      const sortedByYear = allData.sort((a, b) => {
        return a.year - b.year;
      });

      sortedByYear.forEach((item, idx) => {
        evtArray.push(item.event);
        console.log(evtArray);
        if (idx < sortedByYear.length - 1) {
          if (item.year !== sortedByYear[idx + 1].year) {
            allEvents.push({ year: item.year, evs: evtArray });
            evtArray = [];
          }
        }
        if (idx === sortedByYear.length - 1) {
          allEvents.push({ year: item.year, evs: evtArray });
        }
      });
      dispatch({ type: GET_TIMES, allEvents: allEvents, loading: false });
    });
  };
};
