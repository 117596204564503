import "../Display.css";
import ResourceDisplay from "../ResourceDisplay";

const ConferencesDisplay = (props) => {
  const conferences = props.conferences;

  const pageDisplay = conferences.map((conference, idx) => (
    <div className="resource" key={conference.date}>
      <div className="resourceItem">
        <p className="itemTitle">{conference.confName}</p>
      </div>
      <div className="resourceItem">
        <p>{conference.location}</p>
      </div>
      <div className="resourceItem">
        <p>{conference.date}</p>
      </div>
      <div className="resourceItem">
        <a href={conference.website} rel="noreferrer" target="_blank">
          Website
        </a>
      </div>
      {idx < conferences.length - 1 && <hr className="resourceRuler" />}
    </div>
  ));

  return (
    <div className="resources">
      <h2 className="resourceHeading">Conferences</h2>
      <ResourceDisplay>{pageDisplay}</ResourceDisplay>
    </div>
  );
};

export default ConferencesDisplay;
