import React, { useState } from "react";
// import {useSelector, useDispatch} from 'redux'
import { Redirect } from "react-router-dom";
import "../Admin.css";
import axios from "axios";

const CreateSociety = () => {
  const [name, setName] = useState("");
  const [mission, setMission] = useState("");
  const [website, setWebsite] = useState("");
  const [created, setCreated] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const society = {
        name,
      mission,
      website,
    };

    axios
      .post("/createSociety", { society })
      .then((response) => alert(response.data.msg))
      .then(setCreated(true));
  };

  let pageDisplay = "";
  if (!created) {
    pageDisplay = (
      <div className="formContainer">
        <h1 className="formHeading">Enter Society Details</h1>
        <form onSubmit={handleSubmit} className="form">
          <div className="labelInput">
            <label htmlFor="name" className="formLabel">missioniety Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(evt) => setName(evt.target.value)}
              className="formInput"
              placeholder="Society Name"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="mission" className="formLabel">
              Mission
            </label>
            <input
              type="text"
              id="mission"
              name="mission"
              value={mission}
              onChange={(evt) => setMission(evt.target.value)}
              className="formInput"
              placeholder="Mission"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="website" className="formLabel">
              Website
            </label>
            <input
              type="text"
              id="website"
              name="website"
              value={website}
              onChange={(evt) => setWebsite(evt.target.value)}
              className="formInput"
              placeholder="Website"
            />
          </div>
          <input className="submitButton" type="submit" value="Submit" />
        </form>
      </div>
    );
  } else {
    pageDisplay = <Redirect to="/" />;
  }

  return pageDisplay;
};

export default CreateSociety;
