import React, { useState } from 'react'
import {useSelector, useDispatch } from 'react-redux'
import * as allActions from '../store/actions/auth'
import "./auth.css"
import {Redirect} from 'react-router-dom'


const AuthLogin = () => {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
    const dispatch = useDispatch()

    const handleSubmit = (evt) => {
        evt.preventDefault()
        const user = {username, password}
        dispatch(allActions.login(user))
    }
    

    let pageDisplay = ""
    if (!isLoggedIn) {
    pageDisplay =
    <form onSubmit={handleSubmit} className="authForm">
        <div className="labelField">
            <label htmlFor="username">Username</label>
            <input type="text" onChange={(evt) => setUsername(evt.target.value)} name="username" value={username} className="formField" placeholder="Enter username"/>
        </div>
 
        <div className="labelField">
            <label htmlFor="password">Password</label>
            <input type="password" onChange={(evt) => setPassword(evt.target.value)} name="password" value={password} className="formField" placeholder="Enter password"/>
        </div>
        <button type="submit" className="formButton">Submit</button>
    </form> 
    } else {
        pageDisplay =
        alert("Logged in successfully!")
        return <Redirect to="/"
        /> 
    }

    return(
        <div className="authFormContainer">
        <h1>Login to Admin</h1>
        {pageDisplay}
        </div>
    )
}


export default AuthLogin