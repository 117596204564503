import React, { useState, useEffect } from "react";

import BookDisplay from "./BookDisplay";
import Loader from "react-loader-spinner";
import axios from "axios";
import "../Display.css";

const Books = () => {
  const [books, setBooks] = useState([]);


  useEffect(() => {
    axios.get("/books") 
      .then(response => {
          setBooks(response.data.books);
        })
  }, []);

  let pageDisplay = "";
  if (books.length) {
    pageDisplay = 
      <div>
        <BookDisplay books={books} />
      </div>
  } else {
    pageDisplay = 
      <Loader type="ThreeDots" color="#000000" height={80} width={80} />
  }
  return (
    <div className="allResources">
      <h1 className="pageHeading">Books</h1>
      <p className="pageBlurb">
      <p>Below are some general apoptosis books that would be suitable for any researchers that want to get into the field, or for those who just want to expand their knowledge. All of the books selected were published since 2015. We have provided a link that will enable you to view and purchase the book through Amazon. Note, we receive a very small commission purchases made through this link which goes towards maintaining and hosting this site.</p>
      </p>
      {pageDisplay}
    </div>
  );
};

export default Books;
