import React from 'react'
import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, TextRun } from "docx";


const CreateDoc= () => {
const texty = "this is a new sentence."

const handleClick = (evt) => {
evt.preventDefault()
        // Create document
        const doc = new Document();

        // Documents contain sections, you can have multiple sections per document, go here to learn more about sections
        // This simple example will only contain one section
        doc.addSection({
            properties: {},
            children: [
                new Paragraph({
                    children: [
                        new TextRun("Hello World "),
                        new TextRun({
                            text: texty,
                            bold: true,
                        }),
                        new TextRun({
                            text: "\nGithub is the best",
                            bold: true,
                        }),
                    ],
                }),
                new Paragraph({
                    children: [
                        new TextRun("Hello World "),
                        new TextRun({
                            text: "Foo Bar",
                            bold: true,
                        }),
                        new TextRun({
                            text: "\nGithub is the best",
                            bold: true,
                        }),
                    ],
                }),
            ],
        });
        
        // Used to export the file into a .docx file
        Packer.toBlob(doc).then(blob => {
            console.log(blob);
            saveAs(blob, "example.docx");
            console.log("Document created successfully");
          });
        }
      

    return(
       <button onClick={handleClick} style={{marginTop: "100px"}}>Click</button>
    )
}
export default CreateDoc