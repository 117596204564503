import '../Display.css'


const DeathFilter = (props) => {
  return (
    <select
      className="dropDown"
      onChange={props.onChangeHandler}
      value={props.filter}
      name="filter"
    >
      <option value="all" default>
        Select a category...
      </option>
      <option value="all">All</option>
      <option value="prosurvivals">Pro-survivals</option>
      <option value="BAX_BAK_BOK">BAX/BAK/BOK</option>
      <option value="BH3_only">BH3-only proteins</option>
      <option value="BH3_mimetics">BH3-mimetics</option>
      <option value="apoptosome">apoptosome</option>
    </select>
  );
};

export default DeathFilter;
