import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import "../Display.css";
import ClassicsDisplay from "./ClassicsDisplay";
import FilterList from "./FilterList";

const Classics = () => {
  const [classic, setClassic] = useState({});
  const [filter, setFilter] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  const handleDisplayAll = () => {
    setFilteredList("");
    setFilter("")
  };

  const handleFilterChange = (evt) => {
    const filterValue = evt.target.value;
    setFilter(filterValue);
    if (filterValue === "all") {
      setFilteredList("");
    } else {
      const filteredList = classic.filter((paper) =>
        paper.tags.includes(filterValue)
      );
      setFilteredList(filteredList);
    }
  };

  useEffect(() => {
    axios
      .get("/getClassics")
      .then((response) => {
        const allData = response.data.classics;
        const sortedYear = allData.sort((a, b) => {
          return a.year - b.year;
        });
        setClassic(sortedYear);
      })
  }, []);

  let pageDisplay = "";

  if (filteredList.length) {
    pageDisplay = (
      <ClassicsDisplay papers={filteredList} sort="year" filter={null} />
    );
  } else if (Object.keys(classic).length) {
    pageDisplay = (
      <ClassicsDisplay papers={classic} sort="filter" filter={filter} />
    );
  } else {
      pageDisplay = (
        <Loader type="ThreeDots" color="#000000" height={80} width={80} />
      );
  }

  return (
    <div className="labCardContainer" >
      <h1 className="pageHeading">Classic Papers</h1>
      <p className="pageBlurb">
        On this page you will find a number of papers that most people in the
        field would consider "classics". Although most were selected based on
        their high citation numbers, there are several within some of the
        categories (e.g. "discovery") that are less well-cited, though are still
        important for various reasons (e.g. discovery of a new gene/protein).
        This is by no means an exhaustive list and I am guessing we have missed
        some obvious ones, however, the intention is to add to it over time,
        especially with papers in the last 5-or-so years. If you would like to
        nominate any for inclusion, please feel free to email us at
        <span className="emailSpan"> contact@bcl2.co</span>.
      </p>

      {!filteredList.length ? (
        <div>
        <h2 className="classicFilterHead">All classic papers displayed</h2>
            <div className="allFilter">
            <h3 className="filterHeading">Filter by:</h3>
            <FilterList onChangeHandler={handleFilterChange} filter={filter} />
            </div>
        </div>
      ) : (
        <h2>{`Papers filtered by: ${filter} `}</h2>
      )}

      {filteredList.length ? (
        <div className="filterReset">
          {/* <h3 className="filterHeading">Filter the papers by:</h3> */}
          <FilterList onChangeHandler={handleFilterChange} filter={filter} />
          <button className="classicsBtn" onClick={handleDisplayAll}>
            Reset to show all
          </button>
        </div>
      )
    : null
    }
      {pageDisplay}
    </div>
  );
};

export default Classics;
