const ClassicsDisplay = (props) => {
  const classic = props.papers;

  const page = classic.map((paper, idx) => {
    return (
      <div className="datePlusHeading" key={paper.title}>
        {idx === 0 ? (
          <p className="yearHeading">{paper.year}</p>
        ) : classic[idx - 1].year !== paper.year ? (
          <p className="yearHeading">{paper.year}</p>
        ) : null}
        <div className="labCard" style={{ width: "100%" }}>
          <p className="labCardHeading">{paper.title}</p>
          <div className="labCardElements">
            <p className="labCardLabel">Authors:{` ${paper.authors}`}</p>
            <p className="labCardLabel">Reference:{` ${paper.reference}`}</p>
            <p className="labCardLabel">Synopsis:{` ${paper.synopsis}`}</p>
            <span className="pubmedLink">
              <p style={{ margin: 0 }}>{`PubMed:${"\u00A0"}`}</p>
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://pubmed.ncbi.nlm.nih.gov/${paper.pmid}`}
                className="labCardLabel"
                style={{ marginTop: 0 }}
              >{` ${paper.pmid}`}</a>
            </span>
            {paper.fulltext ? (
              <a
                href={paper.fulltext}
                rel="noreferrer"
                target="_blank"
                className="labCardLabel"
              >
                Full text article
              </a>
            ) : (
              <p className="labCardLabel">Full text not available</p>
            )}
            <p style={{ marginBottom: 0, fontWeight: "bold" }}>Abstract</p>
            <p style={{ marginTop: "2px", textAlign: "justify" }}>
              {paper.abstract}
            </p>
          </div>
        </div>
      </div>
    );
  });
  return page;
};

export default ClassicsDisplay;
