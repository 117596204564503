import Papers from "./papers/Papers";
import Banner from "./Banner";
import "./Display.css";

const Home = () => {
  return (
    <div className="homeContainer">
      <Banner />
      <div className="creditContainer">
      <p className="credit">Image courtesty of Georgia Atkin-Smith & Ivan Poon, La Trobe University</p>
      </div>
      <div className="labCardContainer">
        <p className="pageBlurb">
          Welcome to bcl2.co, your one-stop shop for all things related to the
          intrinsic apoptosis pathway. Follow the links in the menubar for pages
          on how the pathway works, its history and classic
          papers, plus other resources such as books, journals, conferences and
          societies. Our hope is that we can expand this{" "}
          <a href="/platform">platform</a> to other modes of cell death, or indeed, any cellular pathway. If you
          are interested in being involved with this, please feel free to contact us at
          contact@bcl2.co  and we can help you start your own site.
        </p>
        <p className="filterBlurb">
          Below you will find the latest papers published in the BCL-2 field. We
          use search parameters that capture papers on all BCL-2 family proteins,
          as well as other relevant topics (e.g. BH3-mimetics). Use the buttons
          to switch between papers published yesterday or those from the past 7
          days or to filter by categories.
        </p>
        <Papers />
      </div>
    </div>
  );
};

export default Home;
