import { GET_PAPERS, FILTER_PAPERS, RESET_FILTER } from "../actions/papers";

const initialState = {
  idlist: [],
  idlistWeek: [],
  paperList: [],
  paperListWeek: [],
  filter: "",
  filterList: [],
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAPERS:
        console.log(JSON.stringify(action.paperListWeek))
      return {
        ...state,
        paperList: action.paperList,
        paperListWeek: action.paperListWeek,
        loading: action.loading,
      };
    case FILTER_PAPERS:
      return {
        ...state,
        filter: action.filter,
        filterList: action.filterList,
        loading: action.loading,
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: "",
        filterList: [],
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default reducer;
