import "./Display.css";

const ResourceDisplay = (props) => {
    return(
    <div className="resourceContainer">
        {props.children}
    </div>
    )
}
export default ResourceDisplay;
