import "../Display.css";
import ResourceDisplay from "../ResourceDisplay";

const JournalsDisplay = (props) => {
  const journals = props.journals;

  const pageDisplay = journals.map((journal, idx) => (
    <div className="resource" key={journal.IF}>
      <div className="resourceItem">
        <p className="itemTitle">{journal.title}</p>
      </div>
      <div className="resourceItem">
        <p>{journal.scope}</p>
      </div>
      <div className="resourceItem">
        <p>{`Impact Factor: ${journal.IF}`}</p>
      </div>
      <div className="resourceItem">
        <a href={journal.homepage} rel="noreferrer" target="_blank">
          Homepage
        </a>
      </div>
      <div className="resourceItem">
        <a href={journal.authorpage} rel="noreferrer" target="_blank">
          Guide to authors
        </a>
      </div>
      {idx < journals.length - 1 && <hr className="resourceRuler" />}
    </div>
  ));

  return (
    <div className="resources">
      <h2 className="resourceHeading">Journals</h2>
      <ResourceDisplay>{pageDisplay}</ResourceDisplay>
    </div>
  );
};

export default JournalsDisplay;
