import dayjs from 'dayjs'
import axios from 'axios'
import PaperList from "./PaperList";
import searchParams from "./SearchList";

export const GET_PAPERS_ID = 'GET_PAPERS_ID'
export const GET_PAPERS = 'GET_PAPERS'
export const FILTER_PAPERS = "FILTER_PAPERS";
export const RESET_FILTER = "RESET_FILTER";

export const getPapers = () => {
    const dateMinus1 = dayjs().subtract(1, 'day').format('YYYY/MM/DD')
    // const dateParams1 = `${dateMinus1}"[Date - Publication] : "3000"[Date - Publication])`

    const dateNow = dayjs().format('YYYY/MM/DD')
    const dateParamsYesterday = `${dateMinus1}"[Date - Publication] : "${dateNow}"[Date - Publication])`

    const dateMinus7 = dayjs().subtract(7, 'day').format('YYYY/MM/DD')
    const dateParamsWeek = `${dateMinus7}"[Date - Publication] : "${dateNow}"[Date - Publication])`
    
    const urlunencoded = '((((((((((((((((((BCL-XL[Text Word]) OR (BCL-2[Text Word])) OR (MCL-1[Text Word])) OR (BCL-W[Text Word])) OR (BFL-1[Text Word])) OR (BIM[Text Word])) OR (BH3-mimetic[Text Word])) OR (BMF[Text Word])) OR (HRK[Text Word])) OR (BID[Text Word])) OR (NOXA[Text Word])) OR (PUMA[Text Word])) OR (BIK[Text Word])) OR (BAX[Text Word])) OR (BAK[Text Word])) OR (BOK[Text Word]) OR (BH3-only[Text Word]) OR (BH3 mimetic[Text Word]) AND ("'
    
    const urlEncoded = encodeURIComponent(urlunencoded)
    // const dateParamsEncoded1 = encodeURIComponent(dateParams1)
    const dateParamsEncodedWeek = encodeURIComponent(dateParamsWeek)
    const dateParamsEncodedYesterday = encodeURIComponent(dateParamsYesterday)

    // const url1 =  `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${urlEncoded}${dateParamsEncoded1}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`

    const url1 =  `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${urlEncoded}${dateParamsEncodedYesterday}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`
  
  
  
    const url2 =  `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${urlEncoded}${dateParamsEncodedWeek}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`

    let idlist = []
    let idlistWeek = []

    return async (dispatch) => {
    await axios.all([
        axios.get(url1),
        axios.get(url2)
      ])
      .then(axios.spread((responseUrl1, responseUrl2) => {        
        idlist = [...new Set(responseUrl1.data.esearchresult.idlist)]
        idlistWeek = [...new Set(responseUrl2.data.esearchresult.idlist)]
      }))
 
    let idlistString = idlist.toString()
    let idlistWeekString = idlistWeek.toString()

    let paperList = []
    let paperListWeek = []

              await axios.get(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistString}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`)  
              .then(response1 => { 
                  paperList = PaperList(response1.data.result);
                })
              .catch(error => console.log("Error" + error))  
        
      
              await axios.get(`https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistWeekString}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`)
              .then(response2 => { 
                paperListWeek = PaperList(response2.data.result);
              })
              .catch(error => console.log("Error" + error))  
          
        await dispatch({type: GET_PAPERS, paperList, paperListWeek, loading: false})
    }
    
}

export const filterPapers = (filter, period) => {
const dateNow = dayjs().format('YYYY/MM/DD')
 const dateMinus2 = dayjs().subtract(1, 'day').format('YYYY/MM/DD')
 const dateMinus7 = dayjs().subtract(7, 'day').format('YYYY/MM/DD')

  const dateParamsYesterday = `${dateMinus2}"[Date - Publication] : "${dateNow}"[Date - Publication])`

  const dateParamsWeek = `${dateMinus7}"[Date - Publication] : "${dateNow}"[Date - Publication])`

  let dateParams = ""
  // if (period === "day") {
  // const date = dayjs().subtract(1, "day").format("YYYY/MM/DD");
  // dateParams = `${date}"[Date - Publication] : "3000"[Date - Publication])`;
  // } else if (period === "week") {
  //   const date= dayjs().subtract(7, "day").format("YYYY/MM/DD");
  //   dateParams = `${date}"[Date - Publication] : "3000"[Date - Publication])`;
  // }

  if (period === "day") {
    dateParams = dateParamsYesterday
    } else if (period === "week") {
      dateParams= dateParamsWeek
    }
  
  const searchTerms = searchParams.find(
    (topic) => Object.keys(topic).toString() === filter
  );
    const urlunencoded = Object.values(searchTerms).toString();
  
  const urlEncoded = encodeURIComponent(urlunencoded);
  const dateParamsEncoded = encodeURIComponent(dateParams);

  const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&retmax=10000&term=${urlEncoded}${dateParamsEncoded}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`;

  let idlist = [];

  return async (dispatch) => {
    await axios.get(url).then((response) => {
      idlist = [...new Set(response.data.esearchresult.idlist)];
    });

    let idlistString = idlist.toString();
    let filterList = [];

    await axios
      .get(
        `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=${idlistString}&api_key=${process.env.REACT_APP_PUBMED_API_KEY}`
      )
      .then((response) => {
        filterList = PaperList(response.data.result);
      })
      .catch((error) => console.log("Error" + error));

    dispatch({ type: FILTER_PAPERS, filterList, filter, loading: false });
  };
};

export const resetFilter = () => {
  return { type: RESET_FILTER, loading: false };
};
