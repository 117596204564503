import React, {useState} from 'react'
// import {useSelector, useDispatch} from 'redux'
import {Redirect} from 'react-router-dom'
import "../Admin.css"
import axios from 'axios'

const CreateClassic = () => {
    const [title, setTitle] = useState("")
    const [year, setYear] = useState("")
    const [authors, setAuthors] = useState("")
    const [reference, setReference] = useState("")
    const [synopsis, setSynopsis] = useState("")
    const [citations, setCitations] = useState("")
    const [abstract, setAbstract] = useState("")
    const [tags, setTags] = useState("")
    const [primary, setPrimary] = useState("")
    const [pmid, setPMID] = useState("")
    const [fulltext, setFulltext] = useState("")
    const [created, setCreated] = useState(false)


    const handleSubmit = (evt) => {
    evt.preventDefault()

    const classic = {title, year, authors, reference, synopsis, abstract, tags, primary, pmid, fulltext}

    axios.post("/createClassic", {classic})
    .then(response => alert(response.data.msg))
    .then(setCreated(true))
}

let pageDisplay = "" 
    if (!created){
    pageDisplay = 
    <div className="formContainer">
        <h1 className="formHeading">Enter Classic Paper Details</h1>
        <form onSubmit={handleSubmit} className="form">
            
            <div className="labelInput">
                <label htmlFor="title" className="formLabel">Paper Title</label>
                <input type="text" id="title" name="title" value={title} onChange={(evt) => setTitle(evt.target.value)} className="formInput" placeholder="Title"/>
            </div>
            <div className="labelInput">
                <label htmlFor="year" className="formLabel">Year</label>
                <input type="text" id="year" name="year" value={year} onChange={(evt) => setYear(evt.target.value)} className="formInput" placeholder="Year"/>
            </div>
            <div className="labelInput">
                <label htmlFor="authors" className="formLabel">Authors</label>
                <input type="text" id="authors" name="authors" value={authors} onChange={(evt) => setAuthors(evt.target.value)} className="formInput" placeholder="Authors"/>
            </div>
            <div className="labelInput">
                <label htmlFor="reference" className="formLabel">Reference</label>
                <input type="text" id="reference" name="reference" value={reference} onChange={(evt) => setReference(evt.target.value)} className="formInput" placeholder="Reference"/>
            </div>
            <div className="labelInput">
                <label htmlFor="synopsis" className="formLabel">Synopsis</label>
                <input type="text" id="synopsis" name="synopsis" value={synopsis} onChange={(evt) => setSynopsis(evt.target.value)} className="formInput" placeholder="Synopsis"/>
            </div>
            <div className="labelInput">
                <label htmlFor="citations" className="formLabel">Citations</label>
                <input type="text" id="citations" name="citations" value={citations} onChange={(evt) => setCitations(evt.target.value)} className="formInput" placeholder="Citation"/>
            </div>
            <div className="labelInput">
                <label htmlFor="abstract" className="formLabel">Abstract</label>
                <textarea cols="50" rows="4" id="abstract" name="abstract" value={abstract} onChange={(evt) => setAbstract(evt.target.value)} className="formInput" placeholder="Abstract"/>
            </div>
            <div className="labelInput">
                <label htmlFor="tags" className="formLabel">Tags</label>
                <input type="text" id="tags" name="tags" value={tags} onChange={(evt) => setTags(evt.target.value)} className="formInput" placeholder="Tags"/>
            </div>
            <div className="labelInput">
                <label htmlFor="primary" className="formLabel">Primary</label>
                <input type="text" id="primary" name="primary" value={primary} onChange={(evt) => setPrimary(evt.target.value)} className="formInput" placeholder="Primary"/>
            </div>
            <div className="labelInput">
                <label htmlFor="pmid" className="formLabel">PMID</label>
                <input type="text" id="pmid" name="pmid" value={pmid} onChange={(evt) => setPMID(evt.target.value)} className="formInput" placeholder="PMID"/>
            </div>
            <div className="labelInput">
                <label htmlFor="fulltext" className="formLabel">Fulltext Link</label>
                <input type="text" id="fulltext" name="fulltext" value={fulltext} onChange={(evt) => setFulltext(evt.target.value)} className="formInput" placeholder="Fulltext Link"/>
            </div>
    
            <input className="submitButton" type="submit" value="Submit"/>

        </form>
    </div>
    } else {
        pageDisplay = <Redirect 
        to="/" 
        />
    }

    return(
       pageDisplay
    )
}

export default CreateClassic