import { REGISTER, LOGIN } from '../actions/auth'
const initialState = { isLoggedIn: false, username: "", token: null}


const reducer = (state = initialState, action) => {
    switch (action.type) {
    case LOGIN : 
        return {...state, isLoggedIn: action.isLoggedIn, username: action.username, token: action.token}
    case REGISTER : 
    console.log("red" +JSON.stringify(action))
        return {...state, isLoggedIn: action.isLoggedIn, username: action.username}
    default: return state
    }
}

export default reducer

