const searchParams = [
  {
    prosurvivals:
      '((((BCL-XL[Text Word]) OR (BCL-2[Text Word])) OR (BCL-W[Text Word])) OR (MCL-1[Text Word])) OR (BFL-1[Text Word]) AND ("',
  },
  {
    BAX_BAK_BOK: '((BAX[Text Word]) OR (BAK[Text Word])) OR (BOK[Text Word]) AND ("',
  },

  {
    BH3_only:
      '(((((((BIM[Text Word]) OR (BID[Text Word])) OR (BIK[Text Word])) OR (BMF[Text Word])) OR (HRK[Text Word])) OR (NOXA[Text Word])) OR (PUMA[Text Word])) OR (BH3-only[Text Word]) AND ("',
  },
  { BH3_mimetics: '(BH3 mimetic[Text Word]) OR (BH3-mimetics[Text Word]) AND ("' },
  {
    apoptosome:
      '(APAF-1[Text Word]) OR (apoptosome[Text Word]) AND ("',
  },
];

export default searchParams;
