import { GET_TIMES } from '../actions/times'

const initialState = {events:[], loading: true}


const reducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_TIMES : 
        console.log("all"+action.allEvents)
        return {...state, events: action.allEvents, loading:action.loading}
    default: return state
    }
}

export default reducer
