import React, { useState } from "react";
// import {useSelector, useDispatch} from 'redux'
import { Redirect } from "react-router-dom";
import "../Admin.css";
import axios from "axios";

const CreateJournal = () => {
  const [title, setTitle] = useState("");
  const [scope, setScope] = useState("");
  const [IF, setIF] = useState("");
  const [homepage, setHomePage] = useState("");
  const [authorpage, setAuthorPage] = useState("");
  const [created, setCreated] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const journal = {
      title,
      scope,
      IF,
      homepage,
      authorpage,
    };

    axios
      .post("/createJournal", { journal })
      .then((response) => alert(response.data.msg))
      .then(setCreated(true));
  };

  let pageDisplay = "";
  if (!created) {
    pageDisplay = (
      <div className="formContainer">
        <h1 className="formHeading">Enter Journal Details</h1>
        <form onSubmit={handleSubmit} className="form">
          <div className="labelInput">
            <label htmlFor="title" className="formLabel">
              Journal Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={(evt) => setTitle(evt.target.value)}
              className="formInput"
              placeholder="Title"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="scope" className="formLabel">
              Scope
            </label>
            <input
              type="text"
              id="scope"
              name="scope"
              value={scope}
              onChange={(evt) => setScope(evt.target.value)}
              className="formInput"
              placeholder="Scope"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="IF" className="formLabel">
            Impact Factor
            </label>
            <input
              type="text"
              id="IF"
              name="IF"
              value={IF}
              onChange={(evt) => setIF(evt.target.value)}
              className="formInput"
              placeholder="Impact Factor"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="homepage" className="formLabel">
              Homepage
            </label>
            <input
              type="text"
              id="homepage"
              name="homepage"
              value={homepage}
              onChange={(evt) => setHomePage(evt.target.value)}
              className="formInput"
              placeholder="Homepage"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="authorpage" className="formLabel">
              Guide to authors
            </label>
            <input
              type="text"
              id="authorpage"
              name="authorpage"
              value={authorpage}
              onChange={(evt) => setAuthorPage(evt.target.value)}
              className="formInput"
              placeholder="Guide to authors"
            />
          </div>
          <input className="submitButton" type="submit" value="Submit" />
        </form>
      </div>
    );
  } else {
    pageDisplay = <Redirect to="/" />;
  }

  return pageDisplay;
};

export default CreateJournal;
