import React, {useState} from 'react'
// import {useSelector, useDispatch} from 'redux'
import {Redirect} from 'react-router-dom'
import "../Admin.css"
import axios from 'axios'

const CreateLab = () => {
    const [headFirst, setHeadFirst] = useState("")
    const [headLast, setHeadLast] = useState("")
    const [institute, setInstitute] = useState("")
    const [country, setCountry] = useState("")
    const [url, setUrl] = useState("")
    const [focus, setFocus] = useState("")
    const [labCreated, setLabCreated] = useState(false)

    // const dispatch = useDispatch()

//     chandleChange = (evt) => {
//     this.setState({[evt.target.name]: evt.target.value})
// }

    const handleSubmit = (evt) => {
    evt.preventDefault()

    const lab = {headFirst, headLast, institute, country, url, focus}

    axios.post("/createLab", {lab})
    .then(response => alert(response.data.msg))
    .then(setLabCreated(true))
}

let pageDisplay = "" 
    if (!labCreated){
    pageDisplay = 
    <div className="formContainer">
        <h1 className="formHeading">Enter Lab Details</h1>
        <form onSubmit={handleSubmit} className="form">
            
            <div className="labelInput">
                <label htmlFor="headFirst" className="formLabel">Laboratory Head - First Name</label>
                <input type="text" id="headFirst" name="headFirst" value={headFirst} onChange={(evt) => setHeadFirst(evt.target.value)} className="formInput" placeholder="Lab Head First name"/>
            </div>
            <div className="labelInput">
                <label htmlFor="headLast" className="formLabel">Laboratory Head - Last Name</label>
                <input type="text" id="headLast" name="headLast" value={headLast} onChange={(evt) => setHeadLast(evt.target.value)} className="formInput" placeholder="Lab Head Last name"/>
            </div>
            <div className="labelInput">
                <label htmlFor="institute" className="formLabel">Institute</label>
                <input type="text" id="institute" name="institute" value={institute} onChange={(evt) => setInstitute(evt.target.value)} className="formInput" placeholder="Institute name"/>
            </div>
            <div className="labelInput">
                <label htmlFor="country" className="formLabel">Country</label>
                <input type="text" id="country" name="country" value={country} onChange={(evt) => setCountry(evt.target.value)} className="formInput" placeholder="Country of lab"/>
            </div>
            <div className="labelInput">
                <label htmlFor="url" className="formLabel">Website</label>
                <input type="text" id="url" name="url" value={url} onChange={(evt) => setUrl(evt.target.value)} className="formInput" placeholder="Lab website URL"/>
            </div>
            <div className="labelInput">
                <label htmlFor="focus" className="formLabel">Reseach Focus</label>
                <input type="text" id="focus" name="focus" value={focus} onChange={(evt) => setFocus(evt.target.value)} className="formInput" placeholder="Research focus of lab"/>
            </div>
    
            <input className="submitButton" type="submit" value="Submit"/>

        </form>
    </div>
    } else {
        pageDisplay = <Redirect 
        to="/" 
        />
    }

    return(
       pageDisplay
    )
}

export default CreateLab