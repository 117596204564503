import React, { useState } from "react";
// import {useSelector, useDispatch} from 'redux'
import { Redirect } from "react-router-dom";
import "../Admin.css";
import axios from "axios";

const CreateTime = () => {
  const [year, setYear] = useState("");
  const [event, setEvent] = useState("");

  const [eventCreated, setEventCreated] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const eventObj = { year, event };

    axios
      .post("/createEvent", { eventObj })
      .then((response) => alert(response.data.msg))
      .then(setEventCreated(true));
  };

  let pageDisplay = "";
  if (!eventCreated) {
    pageDisplay = (
      <div className="formContainer">
        <h1 className="formHeading">Enter Event Details</h1>
        <form onSubmit={handleSubmit} className="form">
          <div className="labelInput">
            <label htmlFor="year" className="formLabel">
              Year
            </label>
            <input
              type="text"
              id="year"
              name="year"
              value={year}
              onChange={(evt) => setYear(evt.target.value)}
              className="formInput"
              placeholder="Year"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="event" className="formLabel">
              Event
            </label>
            <input
              type="text"
              id="event"
              name="event"
              value={event}
              onChange={(evt) => setEvent(evt.target.value)}
              className="formInput"
              placeholder="Event + (reference)"
            />
          </div>
          <input className="submitButton" type="submit" value="Submit" />
        </form>
      </div>
    );
  } else {
    pageDisplay = <Redirect to="/" />;
  }

  return pageDisplay;
};

export default CreateTime;
