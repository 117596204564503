import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";
import "../Display.css";

const TimeLine = (props) => {
  const events = props.events;

  const timeitems = events.map((event) => {
    const paras = event.evs.map((item) => (
      <p className="eventItem" key={item}>
        {`• ${item}`}
      </p>
    ));

    return (
      <TimelineItem
        key={event.year}
        dateText={event.year}
        dateInnerStyle={{ background: "steelblue", color: "white" }}
        style={{ color: "steelblue" }}
      >
        {paras}
      </TimelineItem>
    );
  });

  return (
    <Timeline lineColor={"lightgrey"} className="timeline">
      {timeitems}
    </Timeline>
  );
};

export default TimeLine;
