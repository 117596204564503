import React, { useState, useEffect } from "react";
import ConferenceDisplay from "./conferences/ConferencesDisplay";
import JournalDisplay from "./journals/JournalsDisplay";
import SocietiesDisplay from "./societies/SocietiesDisplay";
import Loader from "react-loader-spinner";
import axios from "axios";
import "./Display.css";

const Resources = () => {
  const [conferences, setConferences] = useState([]);
  const [journals, setJournals] = useState([]);
  const [societies, setSocieties] = useState([]);

  useEffect(() => {
    axios
      .all([
        axios.get("/journals"),
        axios.get("/conferences"),
        axios.get("/societies"),
      ])
      .then(
        axios.spread((response1, response2, response3) => {
          setJournals(response1.data.journals);
          setConferences(response2.data.conferences);
          setSocieties(response3.data.societies);
        })
      );
  }, []);

  let pageDisplay = "";
  if (societies.length || journals.length || conferences.length) {
    pageDisplay = (
      <div>
        <JournalDisplay journals={journals} />
        <ConferenceDisplay conferences={conferences} />
        <SocietiesDisplay societies={societies} />
      </div>
    );
  } else {
    pageDisplay = (
      <Loader type="ThreeDots" color="#000000" height={80} width={80} />
    );
  }
  return (
    <div className="allResources">
      <h1 className="pageHeading">Resources</h1>
      <p className="pageBlurb">
        Below is a series of handy resources (Journals, Conferences, Societies,
        other apoptosis-related links). If you know of other useful links or
        resources that could be added to this page, please contact us at
        contact@bcl2.co.
      </p>
      {pageDisplay}
    </div>
  );
};

export default Resources;
