import '../Display.css'


const FilterList = (props) => {
  return (
    <select
      className="dropDown"
      onChange={props.onChangeHandler}
      value={props.filter}
    >
      <option value="all" default>
        Select a category...
      </option>
      <option value="apoptosome">Apoptosome</option>
      <option value="Bax/Bak">BAX/BAK proteins</option>
      <option value="BH3-mimetics">BH3-mimetics</option>
      <option value="BH3-only">BH3-only proteins</option>
      <option value="cytochrome c">Cytochrome c</option>
      <option value="discovery">Discovery</option>
      <option value="general">General</option>
      <option value="mechanisms">Mechanisms</option>
      <option value="methods">Methods</option>
      <option value="p53">p53</option>
      <option value="pro-survival">Pro-survival proteins</option>
      <option value="structure">Structure</option>
    </select>
  );
};

export default FilterList;
