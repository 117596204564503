
const PaperDisplay = (props) => {
  let time = ""
  if (props.period === "day") {
    time = "yesterday"
  } else {
    time = "in the last week"
  }

  let listDisplay = ""
  
    if (props.paperlist.length) {
    listDisplay = props.paperlist.map((data) => {
      let volume = "";
      if (data.volume === "") {
        volume = " volume/pages not yet available";
      } else {
        volume = `${data.volume}: `;
      }

      return (
        <div className="paperlistContainer" key={data.id}>
          <span>
            <span className="title">{`${data.title} `}</span>
            <span className="authors">{`${data.authors}, `}</span>
            <span className="year">{`(${data.pubdate}), `}</span>
            <span className="journal">{`${data.journal},  `}</span>
            <span className="volume">{`${volume} `}</span>
            <span className="pages">{`${data.pages},  `}</span>
            <span className="doi">{`${data.doi}, `}</span>
            <span className="pmid">
              PMID:{" "}
              <a
                href={`https://www.ncbi.nlm.nih.gov/pubmed/${data.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.id}.
              </a>
            </span>
          </span>
        </div>
      );
    });
  } else {
    !props.filter ? listDisplay = <h3>{`No BCL-2-related papers published ${time}`}</h3> 
    : 
    listDisplay = <h3>{`No ${props.filter.replace("_","-")}-related papers published ${time}`}</h3>
  }
  
  return listDisplay;
};

export default PaperDisplay