import React, { useEffect, useState } from "react";
import PaperDisplay from "./PaperDisplay";
import DeathFilter from "./DeathFilter";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import * as allActions from "../store/actions/papers";
import "./PaperDisplay.css";

const Papers = () => {
  const [displayTime, setDisplayTime] = useState("day");
  const [filterLoading, setFilterLoading] = useState(false);
  const filter = useSelector((state) => state.papers.filter);
  const paperList = useSelector((state) => state.papers.paperList);
  const paperListWeek = useSelector((state) => state.papers.paperListWeek);
  const filterList = useSelector((state) => state.papers.filterList);
  const loading = useSelector((state) => state.papers.loading);

  const dispatch = useDispatch();

  const handleFilter = (evt) => {
    if (evt.target.value === "all") {
      dispatch(allActions.resetFilter());
    } else {
      setFilterLoading(true);
      dispatch(allActions.filterPapers(evt.target.value, displayTime)).then(() =>
        setFilterLoading(false)
      );
    }
  };

  const handlePeriod = (evt) => {
    if (!filter) {
      setDisplayTime(evt.target.value)
    } else {
      setFilterLoading(true);
      setDisplayTime(evt.target.value)
      dispatch(allActions.filterPapers(filter, evt.target.value)).then(() =>
        setFilterLoading(false)
      );
    }
  };


  useEffect(() => {
    dispatch(allActions.getPapers());
  }, []);

  let listDisplay = "";
  if (loading || filterLoading) {
    listDisplay = (
      <Loader
        type="ThreeDots"
        color="#000000"
        height={80}
        width={80}
        className="loader"
      />
    );
  } else if (displayTime === "day" && !filter) {
    listDisplay = <PaperDisplay paperlist={paperList} period={displayTime}/>;
  } else if (displayTime === "week" && !filter) {
    listDisplay = <PaperDisplay paperlist={paperListWeek} period={displayTime}/>;
  } else if (filter) {
    listDisplay = <PaperDisplay paperlist={filterList} filter={filter} period={displayTime}/>;
  }

  return (
    <div>
      <div className="btnContainer">
        <button
          onClick={handlePeriod}
          name="period"
          value="day"
          style={
            displayTime === "day"
              ? { backgroundColor: "black", color: "white" }
              : { backgroundColor: "white  ", color: "black" }
          }
          className="paperSortBtn"
        >
          YESTERDAY
        </button>
        <button
          onClick={handlePeriod}
          name="period"
          value="week"
          style={
            displayTime === "week"
              ? { backgroundColor: "black", color: "white" }
              : { backgroundColor: "white", color: "black" }
          }
          className="paperSortBtn"
        >
          LAST 7 DAYS
        </button>
        </div>
        
        <div className="paperFilterContainer">
          <h3 className="filterLabel">Filter papers by:</h3>
          <DeathFilter onChangeHandler={handleFilter} />
        </div>
       
      {loading && <h2 className="fetchingText">Fetching Papers...</h2>}
       
        {(displayTime === "day" && !filter) ? 
        <h2 className="displayModeHeading">Papers published yesterday</h2>
        : (displayTime === "week" && !filter) ? 
        <h2 className="displayModeHeading">Papers published this week</h2>
        : (displayTime === "day" && filter) ? 
        <h2 className="displayModeHeading">{`Papers published yesterday and filtered by ${filter.replace("_","-")}`}</h2>
        : (displayTime === "week" && filter) ? 
        <h2 className="displayModeHeading">{`Papers published this week and filtered by ${filter.replace("_","-")}`}</h2>
        : null
      }
        


      {listDisplay}
      </div>
  );
};

export default Papers;
