import "./Display.css"
// import { useMediaQuery } from 'react-responsive'

const Banner = () => {
  // const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  return (
    <div className="banner" style={{backgroundImage: "url('Website.png')"}}>
        <div id="color-overlay"></div>
        <h1 className="bannerTitle">BCL2.co</h1>
      </div>

  );
};

export default Banner;
