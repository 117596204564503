import React from "react";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import Toolbar from "./Toolbar";
import Home from "./Home";
import AuthLogin from "./Auth/AuthLogin";
import AuthRegister from "./Auth/AuthRegister";
import CreateLab from "./labs/CreateLab";
import Labs from "./labs/Labs";
import Pathway from "./Pathway";
import CreateClassic from "./classics/CreateClassic";
import Classics from "./classics/Classics";
// import PaperDisplay from "./PaperDisplay";
import Papers from "./papers/Papers"
import CreateDoc from "./labs/CreateDoc";
import Times from "./timeline/Times";
import CreateTime from "./timeline/CreateTime";
import Journals from "./journals/Journals";
import CreateJournal from "./journals/CreateJournal";
import Books from "./books/Books";
import CreateBook from "./books/CreateBook";
import Conferences from "./conferences/Conferences";
import CreateConference from "./conferences/CreateConference";
import Societies from "./societies/Societies";
import CreateSociety from "./societies/CreateSociety";
import Resources from "./Resources";
import Platform from "./Platform";
import Contact from "./Contact";
import "./App.css";



const App = ({ history }) => {
  return (
    <div className="App">
      <ConnectedRouter history={history}>
        <Toolbar />
        <Switch>
          <Route exact path="/register" render={() => <AuthRegister />} />
          <Route exact path="/login" render={(routeProps) => <AuthLogin />} />
          <Route exact path="/doc" render={(routeProps) => <CreateDoc />} />
          <Route exact path="/" render={() => <Home />} />
          <Route exact path="/pathway" render={() => <Pathway />} />
          <Route exact path="/papers" render={() => <Papers />} />
          <Route exact path="/labs" render={() => <Labs />} />
          <Route exact path="/createLab" render={() => <CreateLab />} />
          <Route exact path="/classics" render={() => <Classics />} />
          <Route exact path="/createClassic" render={() => <CreateClassic />} />
          <Route exact path="/times" render={() => <Times />} />
          <Route exact path="/createEvent" render={() => <CreateTime />} />
          <Route exact path="/journals" render={() => <Journals />} />
          <Route exact path="/createJournal" render={() => <CreateJournal />} />
          <Route exact path="/allBooks" render={() => <Books />} />
          <Route exact path="/createBook" render={() => <CreateBook />} />
          <Route exact path="/conferences" render={() => <Conferences />} />
          <Route exact path="/createConference" render={() => <CreateConference />} />
          <Route exact path="/Societies" render={() => <Societies />} />
          <Route exact path="/createSociety" render={() => <CreateSociety />} />
          <Route exact path="/resources" render={() => <Resources />} />
          <Route exact path="/platform" render={() => <Platform />} />
          <Route exact path="/contact" render={() => <Contact />} />
        </Switch>
      </ConnectedRouter>
    </div>
  );
};

export default App;
