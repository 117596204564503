import "./Display.css";

const Contact = () => {
  return (
    <div className="platformContainer">
      <h1 className="pageHeading">Contact</h1>
      <p>
        This website was created with the idea that it would evolve in response
        to any feedback or ideas provided by the BCL-2 community of researchers.
        In general, it has been designed to easily update, edit or correct or
        add to any of the pages, A such, please feel free to email me at
        <span className="emailSpan"> contact@bcl2.co</span> about anything you feel would improve the site - we are more than happy to take on your ideas and implement them as necessary!
      </p>
      <p>If you would like to contact me about my own BCL-2 research (see <a href="https://www.celldeathlab.com" target="_blank" rel="noopener noreferrer">celldeathlab.com</a>), you can email me at <span className="emailSpan"> doug.fairlie@onjcri.org.au</span></p>
    </div>
  );
};

export default Contact;
