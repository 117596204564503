import "../Display.css";
import ResourceDisplay from "../ResourceDisplay";

const BooksDisplay = (props) => {
  const books = props.books;

  const pageDisplay = books.map((book, idx) => (
    <div className="resource" key={book.image}>
        <div className="imageTitle">
        <img src={book.image} alt="book cover" className="bookImage"/>
        <div className="resourceItem">
            <p className="itemTitle">{book.title}</p>
        </div>
        </div>
      <div className="resourceItem">
        {book.author && <p>{`Author: ${book.author}`}</p>}
      </div>
      <div className="resourceItem">
        {book.editor && <p>{`Editor: ${book.editor}`}</p>}
      </div>
      <div className="resourceItem" style={{fontWeight:"bold"}}>
      <p>Publisher Blurb</p>
      </div>
      <div className="resourceItem">
        <p>{book.blurb}</p>
      </div>
      <div className="resourceItem">
        <a href={book.amzlink} rel="noreferrer" target="_blank">
          View on Amazon
        </a>
      </div>
      {idx < books.length - 1 && <hr className="resourceRuler" />}
    </div>
  ));

  return (
    <div className="resources">
      <ResourceDisplay>{pageDisplay}</ResourceDisplay>
    </div>
  );
};

export default BooksDisplay;
