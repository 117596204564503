import React from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import "./Toolbar.css";

const Toolbar = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 910;

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  let bar = "";
  if (width > breakpoint) {
    bar = (
      <nav className="nav">
        <div>
          <Link to="/" className="link">
            HOME
          </Link>
          <Link to="/pathway" className="link">
            Pathway
          </Link>
          <Link to="/times" className="link">
            Timeline
          </Link>
          <Link to="/labs" className="link">
            Labs
          </Link>
          <Link to="/classics" className="link">
            Classic Papers
          </Link>
          <Link to="/resources" className="link">
            Resources
          </Link>
          <Link to="/allBooks" className="link">
            Books
          </Link>
        </div>
        <div>
          <Link to="/platform" className="link">
            Platform
          </Link>
          <Link to="/contact" className="link">
            Contact
          </Link>
        </div>
      </nav>
    );
  } else {
    bar = (
      <nav className="nav" style={{ backgroundColor: "black" }}>
        <Menu
          customBurgerIcon={
            <img
              src={
                "https://upload.wikimedia.org/wikipedia/commons/5/59/Hamburger_icon_white.svg"
              }
              alt="burger menu icon"
            />
          }
        >
          <a id="home" className="menu-item" href="/">
            Home
          </a>
          <a id="pathway" className="menu-item" href="/pathway">
            Pathway
          </a>
          <a id="labs" className="menu-item" href="/labs">
            Labs
          </a>
          <a id="times" className="menu-item" href="/times">
            Timeline
          </a>
          <a id="classics" className="menu-item" href="/classics">
            Classic Papers
          </a>
          <a id="resources" className="menu-item" href="/resources">
            Resources
          </a>
          <a id="platform" className="menu-item" href="/platform">
            Platform
          </a>
          <a id="contact" className="menu-item" href="/contact">
            Contact
          </a>
          <a id="books" className="menu-item" href="/allBooks">
            Books
          </a>
        </Menu>
      </nav>
    );
  }

  return bar;
};

export default Toolbar;
