import "./Pathway.css";

const Pathway = () => {
  return (
    <div className="pathwayContainer">
      <h1 className="pathwayHeading">The BCL-2 regulated cell death pathway</h1>
      <h3 className="sectionHeading">Overview</h3>
      <p className="section">
        At its core the BCL-2 regulated pathway can be considered relatively
        straightforward – as described by Jerry Adams and Suzanne Cory, “a
        tripartite regulatory cassette (1)”, in which three sub-groups of
        proteins interact with each other to promote or inhibit cell death. Of
        course, this is a gross oversimplification and even after more than 30
        years of research, we are still learning about many important details of
        cell death regulation by these proteins.
      </p>
    
      <p className="section">
      Below is a brief “primer” for those wanting to gain an understanding of the basics of how intrinsic apoptosis is exerted and controlled at a molecular level. It by no means covers all aspects of the process, and I would refer you to some of the many excellent reviews [e.g. see refs (2-8) for some of the more highly cited ones over the last decade] and books (see our books page <a href="/books">here</a>) on the subject for more information on the BCL-2 proteins, how they regulate apoptosis and contribute to disease. See also our “Timeline” page for an overview of the history of BCL-2 research and the “Classic Papers” page for most of the critical papers in the field.   
      </p>

      <h3 className="sectionHeading">The family</h3>
      <p className="section">
      The BCL-2 family of proteins consists of three distinct classes of molecules – the pro-survival BCL-2-like proteins (BCL-2 itself, BCL-XL, BCL-W, MCL-1, BFL-1), and two sub-groups of pro-apoptotic proteins: the effector proteins BAX, BAK and BOK (hereafter referred collectively as the BAX/BAK proteins) and the triggers of the process, the BH3-only proteins (BIM, BID, BAD, PUMA, NOXA, BMF, BIK, HRK) (Figure 1). This is a protein “family” in the sense that all members are related to each other though the conservation of regions of sequence homology called the “BCL-2 Homology” (BH) domains (Figure 1). These are not really protein domains in the structural sense, but rather conserved sequence motifs ranging in length from 6 residues for the BH4 domain to around 12 residues for the BH1 domain. The pro-survival and BAX/BAK proteins all possess four of these motifs (BH1-BH4), whereas the BH3-only proteins have just the BH3 domain (Figure 1). This sequence conservation was the basis for cloning of many of the members of the family following the discovery of the founding member BCL-2 (9). See our “Classic Papers” page for links to some of the critical papers on the discovery and characterisation of these proteins.
      </p>
      <img className="pathwayFig1" src="/Asset1schem.png" alt="domain structure figure"/>
      <p className="figureLegend_long"><span className="figureTitle">Figure 1:</span> Schematic of BCL-2 family protein BH domain arrangement. For pro-survival proteins BCL-XL was used as the prototype (234 amino acids), though some pro-suvrival proteins are somewhat shorter; for BAX/BAK, BAX (193 amino acids) was used as the prototype and for the BH3-only proteins BimS (short isoform) was used (109 amino acids). B) Sequences of BH3 domains from pro-apoptotic proteins - the four conserved hydrophobic residues that form the core BH3 sequence are indicated. The small conserved residues are shaded grey. C) Selectivity of BH3-only proteins for pro-survival proteins.</p> 
      
      <h3 className="sectionHeading">BH3-only proteins</h3>
      <p className="section">
      Although they are structurally distinct from both the pro-survival and BAX/BAK proteins, we will describe them first as they initiate the pathway, so are the obvious starting point for discussion of the pathway as a whole. They also enable us to introduce the BH3 domain, the critical functional element of the BCL-2 proteins as it mediates interactions between all of the family members.
      </p>
      <p className="section">
      Cell death mediated by the BCL-2 regulated pathway is initiated following a plethora of microenvironmental stresses ranging from DNA damage, withdrawal of growth factors, the presence of reactive oxygen species and ER stress through to anoikis, defects in mitosis and numerous others (Figure 2A). These stresses lead to upregulation or “activation” of the BH3-only proteins via various mechanisms including transcriptional induction (e.g. PUMA and NOXA by p53, BIM by MYC (9-11)) and post-translational modifications (e.g. BID by caspase 8 cleavage, dissociation of BIM from dynein light chain) (12-14). Post-translational modifications to BH3-only proteins can also inhibit their activity (such a serine phosphorylation within the BH3 domain of BAD) (15). Upregulated or activated BH3-only proteins can then bind to either the pro-survival proteins, or in the case of certain family members, engage with BAX and BAK (Figure 2A). The consequences of these interactions will be described below, but the key to all of them is the BH3 domain.
      </p>
      <p className="section">
      Unlike the pro-survival proteins and BAX/BAK, and with the exception of BID, the BH3-only proteins are all intrinsically unstructured (16). Most also possess a hydrophobic C-terminal region that enables them to interact or embed in membranes such as the outer mitochondrial membrane. As their name suggests, the defining feature of these proteins is the BH3 domain which was first recognised following the discovery of BIK (17). This is a motif of 12 amino acids that is broadly characterised as h1-s-X-X-h2-X-X-h3-s-D-X-h4 where h1-h4 are hydrophobic amino acids with h2 always being a leucine, and “s” being small amino acids such as glycine, alanine or serine (Figure 2B). Differences within these sequences are critical for the selectivity of BH3-only proteins for downstream binding partners, an essential feature of apoptosis regulation. For example, BIM and PUMA are promiscuous binders and can interact with all pro-survival proteins with high-affinity whereas BAD is more selective and just binds to BCL-2, BCL-XL and BCL-W, and NOXA has strong selectivity for MCL-1 (18-23) (Figure 2C). Similarly, not all BH3-only proteins can interact with BAX/BAK. Those that do are referred to as “activators” and include BIM and BID, though there is evidence PUMA and NOXA can also bind and activate them (20, 21, 23-27). 
      </p>
      <img className="pathwayFig2" src="/Asset2BH3.png" alt="BH3-only figure"/>
      <p className="figureLegend_long"><span className="figureTitle">Figure 2:</span> A) Schematic of the BCL-2 pathway. B) Sequences of BH3 domains from pro-apoptotic proteins - the four conserved hydrophobic residues that form the core BH3 sequence are indicated. The small conserved residues are shaded grey. C) Selectivity of some of the BH3-only proteins for pro-survival proteins.</p> 

      <h3 className="sectionHeading">Pro-survival proteins</h3>
      <p className="section">
      The pro-survival proteins lie at the centre of the BCL-2-regulated cell death pathway as they engage both the upstream BH3-only proteins and the downstream BAX/BAK effector proteins (Figure 2A). Structurally these proteins consist of nine alpha helices (alpha1-9) that fold into a globular structure with alpha5 and alpha6 forming a hairpin-like structure upon which the other helices pack (Figure 3A) (28). All pro-survival proteins possess a C-terminal transmembrane domain that enables them to embed into membranes such as the mitochondria or in some cases ER, though they are not all exclusively membrane-bound and can move between these organelles and the cytosol. For example,  BCL-2 is nearly always bound to the mitochondria or ER whereas a significant proportion of BCL-XL can be found either in the cytosol or on mitochondria, and appears to be shuttled or “retrotranslocated”, together with BAX, between the two (29). 
      </p>
      <p className="section">
      Perhaps the most obvious feature of the pro-survival proteins is a large hydrophobic groove (30-36). This is the interaction site for the BH3 domain of both the BH3-only proteins and BAX/BAK (Figures 3B,C), and it is by sequestering these proteins that the pro-survival proteins inhibit apoptosis by mechanisms that will be described in more detail below (see “Regulation”). These grooves are predominantly formed by the alpha2-alpha6 helices, though they all have distinguishing characteristics that also determines their specificity for different BH3 domain sequences.
      </p>
      <img className="pathwayFig3" src="/Asset24x.png" alt="pro-survivals figure"/>
      <p className="figureLegend_long"><span className="figureTitle">Figure 3:</span> A) Crystal structure of BCL-XL (PDB: 1PQ0) showing the canonical arrangment of helices. The C-terminal transmembrane helix 9 is not shown as this was deleted to enable expression and crystallisation of the protein. B) Crystal structure of BCL-XL bound to BIM BH3 peptide (cyan) (PDB: 1PQ1). C) Surface model of the structure in B) showing the BH3 binding groove. The BH3-domains of all BH3-only proteins and BAX/BAK interact similarly.</p>
      
      <h3 className="sectionHeading">BAX/BAK</h3>
      <p className="section">
      BAX, BAK and the related BOK are usually referred to as the apoptotic “effector” or “executioner” proteins. BAX and BAK are considered essential for cell death as their combined deletion renders most cells resistant to many apoptotic stimuli (37). All possess the same globular structure as the pro-survival proteins (38, 39) (Figure 4A). However, what distinguishes them is their ability to execute the cell death program which is achieved following their assembly into oligomeric structures that form pores in the mitochondria, enabling escape of factors such as cytochrome c into the cytosol. It has been known for some time that certain BH3-only proteins (the aforementioned “activators”) can interact somewhat transiently with BAX and BAK to induce a large conformation change in these proteins. Structural and biochemical studies have shown this can occur at one of two sites – the canonical hydrophobic groove analogous to that found on pro-survival proteins, or at a back-side site involving alpha1 and alpha6 (40-44).</p>
        <p> Interactions within the groove of BAX/BAK, or exposure to detergents, induces dissociation of alpha1-alpha5 (“core” domain) from alpha6-alpha-8 (“latch domain”) accompanied by release of alpha1 (Figure 4B). These changes lead to exposure of the BH3 domain (on alpha2) which can now engage the groove of a second BAX/BAK molecule, forming a symmetrical dimer (45) that presumably nucleates the formation of higher-order oligomers, and ultimately the rupture of the outer mitochondrial membrane. There are not yet any structures of these larger oligomeric forms so the nature of the “pores” themselves remains unclear. However, recent structural studies have shown how the proteins probably dimerise (Figure 4C) (45) and that phospholipids associated with membranes may themselves participate in the assembly of these structures by bridging the core domains of neighbouring dimers (46).</p>
        
        <img className="pathwayFig4" src="/Asset34x.png" alt="bax/bak structure figures"/>
                <p className="figureLegend_long"><span className="figureTitle">Figure 4:</span> A) NMR strcuture of BAX (PDB: 1F16) with the alpha9 helix shown in black engaging the BH3 binding groove. B) Structure of BAX following interaction with BAX BH3 peptide (cyan) (PDB: 4BD6). The structure unfolds into core and latch domains. C) Crystal structure of BAK BH3-in-goove dimer (PDB: 7K02)  induced by treatment with detergent. </p>
      
      <h3 className="sectionHeading">Regulation</h3>
      <p className="section"> How the pathway is regulated downstream of BH3-only proteins was a source of some controversy for a number of years in the early 2000’s. One of the very earliest mechanistic papers showed that BCL-2 pro-survival proteins inhibited apoptosis via direct interactions with the BAX/BAK (47), which was supported by a number of subsequent studies and  became referred to as the “indirect” model or “MODE 2” regulation (48, 49). However, it also became apparent that the ability of pro-survival proteins to sequester BH3-only proteins, particularly the activators, was also important (20, 21, 50). In this, so-called “direct” activation model (or “MODE 1”), sequestered activators, must be released from their pro-survival protein partners by other BH3-only proteins that cannot directly activate BAX/BAK (i.e. the “sensitisers”), making them available for activation of the executions. It is now generally accepted that both of these mechanisms are at play (22), though recent studies in which all of the BH3-only proteins were deleted from cells showed that apoptosis could proceed in their absence, and that conformational changes in BAX/BAK could be induced simply through interactions with membranes (51, 52). Nevertheless, the direct mode of regulation is likely to be critical in terms of determining whether a cell is “primed” for death, an important feature of cancer cells that dictates their sensitivity or resistance to treatment (19).</p>
      
      <h3 className="sectionHeading">Downstream of BAX/BAK – the apoptosome</h3>
      <p className="section">In mammals, the release of cytochrome c from the mitochondria following BAX/BAK pore formation enables the assembly of the apoptosome. This occurs when the cytochrome c binds to APAF-1, enabling it to oliomerize into an large heptameric scaffold that serves as a platform for interactions with the caspase 9 zymogen via homotypic interactions between caspase recruitment domains (CARD) on both caspase 9 and APAF-1 (Figure 5). This assembled holoenzyme is required for auto-activation of caspase 9 by proteolysis, and then subsequent activation of caspases 3 and 7 zymogens following their proteolytic cleavage by activated caspase 9. This process is now quite well-characterised structurally (e.g. (53, 54)). Activated caspase 3/7 can cleave hundreds of intracellular substrates which ultimately results in the death of the cell. However, it should also be noted that whilst apoptosome formation accelerates cell death, rupture of mitochondria alone by BAX/BAK is sufficient to cause the demise of the cell as APAF-1-deficient cells will still die in response to various apoptotic stimuli (55). It should also be noted that the nature of the apoptosome is not identical in all organisms, for example, in C. elegans it is octameric, does not require cytochrome c for assembly, and the APAF-1 orthologue (CED-4) is regulated by direct interaction with the pro-survival protein, CED-9 (56).</p>
      <img className="pathwayFig5" src="/Asset44x.png" alt="apoptosome figure"/>
      <p className="figureLegend_long"><span className="figureTitle">Figure 5:</span> Cryo-EM structure of the active human apoptosome (PDB: 5JUY). APAF-1: green, cytcochrome c: dark blue, pro-caspase 9: cyan.</p>

      <h3 className="sectionHeading">Cancer and BH3-mimetics</h3>
      <p className="section">The involvement of BCL-2 proteins in cancer extends to the very first days of our knowledge of the family when the BCL-2 gene itself was discovered at the breakpoint of a chromosomal translocation found in some haematological cancers (57, 58). Subsequently it was found that BCL-2 is an proto-oncogene that functions to promote tumour cell survival rather than proliferation (59), and that it acts co-operatively with other oncogenes to promote tumourigenesis (60). It is now recognized that defective apoptosis signalling is a hallmark of most if not all cancers (61) and can involve deregulation of any of the pro-survival proteins (e.g. over-expression due to gene amplification), as well some pro-apoptotic molecules (e.g. inactivating mutations). Due to the vast nature of this topic, we refer you to some reviews that will provide a more complete picture of how BCL-2 proteins are involved in cancer (refs (3, 62-65)). 
      </p>
      <p className="section">Perhaps the biggest development in the field in the last two decades has been the discovery of “BH3-mimetic drugs”. These small molecule compounds mimic the BH3 domain of pro-apoptotic proteins (hence the name), binding into the hydrophobic groove of the BCL-2 pro-survival proteins to activate apoptosis (Figure 6A). Beginning in 2005 with the report of ABT-737 that could bind to BCL-2, BCL-XL and BCL-W with low nanomolar affinity (66), there is now a plethora of similar compounds specific for different pro-survival family members (7, 67) (Figure 6B). Many of these are now in, or have undergone clinical trials, though only Venetoclax targeting BCL-2 (68), is currently used in clinical practice and showing great success in some haematological cancers. However, it is anticipated that other compounds being trialled, such as those targeting MCL-1, will also find their way into the clinic in the future.</p>
      <img className="pathwayFig6" src="/Asset6BH3s.png" alt="BH3-mimetic figure"/>
      <p className="figureLegend_long"><span className="figureTitle">Figure 6: </span>A) Crystal structure of BCL-XL bound to ABT-737 (mauve) (PDB: 2YXJ). The drug occupies the same groove that BH3 peptides engage. B) Some of the published BH3 mimetics and their pro-survival protein targets.</p>
      
    <h3 className="sectionHeading">References</h3>
    <p className="section">
    1.	Adams JM, Cory S. Bcl-2-regulated apoptosis: mechanism and therapeutic potential. Curr Opin Immunol. 2007;19(5):488-96.<br/>
    2.	Youle RJ, Strasser A. The BCL-2 protein family: opposing activities that mediate cell death. Nat Rev Mol Cell Biol. 2008;9(1):47-59.<br/>
    3.	Czabotar PE, Lessene G, Strasser A, Adams JM. Control of apoptosis by the BCL-2 protein family: implications for physiology and therapy. Nat Rev Mol Cell Biol. 2014;15(1):49-63.<br/>
    4.	Tait SW, Green DR. Mitochondria and cell death: outer membrane permeabilization and beyond. Nat Rev Mol Cell Biol. 2010;11(9):621-32.<br/>
    5.	Adams JM, Cory S. The Bcl-2 apoptotic switch in cancer development and therapy. Oncogene. 2007;26(9):1324-37.<br/>
    6.	Delbridge AR, Grabow S, Strasser A, Vaux DL. Thirty years of BCL-2: translating cell death discoveries into novel cancer therapies. Nat Rev Cancer. 2016;16(2):99-109.<br/>
    7.	Ashkenazi A, Fairbrother WJ, Leverson JD, Souers AJ. From basic apoptosis discoveries to advanced selective BCL-2 family inhibitors. Nat Rev Drug Discov. 2017;16(4):273-84.<br/>
    8.	Singh R, Letai A, Sarosiek K. Regulation of apoptosis in health and disease: the balancing act of BCL-2 family proteins. Nat Rev Mol Cell Biol. 2019;20(3):175-93.
    9.	Nakano K, Vousden KH. PUMA, a novel proapoptotic gene, is induced by p53. Mol Cell. 2001;7(3):683-94.<br/>
    10.	Oda E, et al. Noxa, a BH3-only member of the Bcl-2 family and candidate mediator of p53-induced apoptosis. Science. 2000;288(5468):1053-8.<br/>
    11.	Egle A, Harris AW, Bouillet P, Cory S. Bim is a suppressor of Myc-induced mouse B cell leukemia. Proc Natl Acad Sci U S A. 2004;101(16):6164-9.<br/>
    12.	Li H, Zhu H, Xu CJ, Yuan J. Cleavage of BID by caspase 8 mediates the mitochondrial damage in the Fas pathway of apoptosis. Cell. 1998;94(4):491-501.<br/>
    13.	Luo X, Budihardjo I, Zou H, Slaughter C, Wang X. Bid, a Bcl2 interacting protein, mediates cytochrome c release from mitochondria in response to activation of cell surface death receptors. Cell. 1998;94(4):481-90.<br/>
    14.	Puthalakath H, Huang DC, O'Reilly LA, King SM, Strasser A. The proapoptotic activity of the Bcl-2 family member Bim is regulated by interaction with the dynein motor complex. Mol Cell. 1999;3(3):287-96.<br/>
    15.	Datta SR, et al. Akt phosphorylation of BAD couples survival signals to the cell-intrinsic death machinery. Cell. 1997;91(2):231-41.<br/>
    16.	Hinds MG, et al. Bim, Bad and Bmf: intrinsically unstructured BH3-only proteins that undergo a localized conformational change upon binding to prosurvival Bcl-2 targets. Cell Death Differ. 2007;14(1):128-36.<br/>
    17.	Boyd JM, et al. Bik, a novel death-inducing protein shares a distinct sequence motif with Bcl-2 family proteins and interacts with viral and cellular survival-promoting proteins. Oncogene. 1995;11(9):1921-8.<br/>
    18.	Chen L, et al. Differential targeting of prosurvival Bcl-2 proteins by their BH3-only ligands allows complementary apoptotic function. Mol Cell. 2005;17(3):393-403.
    19.	Certo M, et al. Mitochondria primed by death signals determine cellular addiction to antiapoptotic BCL-2 family members. Cancer Cell. 2006;9(5):351-65.<br/>
    20.	Letai A, et al. Distinct BH3 domains either sensitize or activate mitochondrial apoptosis, serving as prototype cancer therapeutics. Cancer Cell. 2002;2(3):183-92.<br/>
    21.	Kuwana T, et al. BH3 domains of BH3-only proteins differentially regulate Bax-mediated mitochondrial membrane permeabilization both directly and indirectly. Mol Cell. 2005;17(4):525-35.<br/>
    22.	Llambi F, et al. A unified model of mammalian BCL-2 protein family interactions at the mitochondria. Mol Cell. 2011;44(4):517-31.<br/>
    23.	Kim H, et al. Stepwise activation of BAX and BAK by tBID, BIM, and PUMA initiates mitochondrial apoptosis. Mol Cell. 2009;36(3):487-99.<br/>
    24.	Hockings C, et al. Bid chimeras indicate that most BH3-only proteins can directly activate Bak and Bax, and show no preference for Bak versus Bax. Cell Death Dis. 2015;6:e1735.<br/>
    25.	Ren D, et al. BID, BIM, and PUMA are essential for activation of the BAX- and BAK-dependent cell death program. Science. 2010;330(6009):1390-3.<br/>
    26.	Dai H, Pang YP, Ramirez-Alvarado M, Kaufmann SH. Evaluation of the BH3-only protein Puma as a direct Bak activator. J Biol Chem. 2014;289(1):89-99.<br/>
    27.	Pang YP, et al. Bak Conformational Changes Induced by Ligand Binding: Insight into BH3 Domain Binding and Bak Homo-Oligomerization. Sci Rep. 2012;2:257.<br/>
    28.	Lee EF, Fairlie WD. The Structural Biology of Bcl-xL. Int J Mol Sci. 2019;20(9).
    29.	Popgeorgiev N, Jabbour L, Gillet G. Subcellular Localization and Dynamics of the Bcl-2 Family of Proteins. Front Cell Dev Biol. 2018;6:13.<br/>
    30.	Sattler M, et al. Structure of Bcl-xL-Bak peptide complex: recognition between regulators of apoptosis. Science. 1997;275(5302):983-6.<br/>
    31.	Day CL, et al. Structure of the BH3 domains from the p53-inducible BH3-only proteins Noxa and Puma in complex with Mcl-1. J Mol Biol. 2008;380(5):958-71.<br/>
    32.	Czabotar PE, et al. Structural insights into the degradation of Mcl-1 induced by BH3 domains. Proc Natl Acad Sci U S A. 2007;104(15):6217-22.<br/>
    33.	Herman MD, et al. Completing the family portrait of the anti-apoptotic Bcl-2 proteins: crystal structure of human Bfl-1 in complex with Bim. FEBS Lett. 2008;582(25-26):3590-4.<br/>
    34.	Petros AM, Olejniczak ET, Fesik SW. Structural biology of the Bcl-2 family of proteins. Biochim Biophys Acta. 2004;1644(2-3):83-94.<br/>
    35.	Ku B, Liang C, Jung JU, Oh BH. Evidence that inhibition of BAX activation by BCL-2 involves its tight and preferential interaction with the BH3 domain of BAX. Cell Res. 2011;21(4):627-41.<br/>
    36.	Lee EF, et al. Crystal structure of a BCL-W domain-swapped dimer: implications for the function of BCL-2 family proteins. Structure. 2011;19(10):1467-76.<br/>
    37.	Wei MC, et al. Proapoptotic BAX and BAK: a requisite gateway to mitochondrial dysfunction and death. Science. 2001;292(5517):727-30.<br/>
    38.	Moldoveanu T, et al. The X-ray structure of a BAK homodimer reveals an inhibitory zinc binding site. Mol Cell. 2006;24(5):677-88.<br/>
    39.	Suzuki M, Youle RJ, Tjandra N. Structure of Bax: coregulation of dimer formation and intracellular localization. Cell. 2000;103(4):645-54.<br/>
    40.	Gavathiotis E, et al. BAX activation is initiated at a novel interaction site. Nature. 2008;455(7216):1076-81.<br/>
    41.	Robin AY, et al. Crystal structure of Bax bound to the BH3 peptide of Bim identifies important contacts for interaction. Cell Death Dis. 2015;6:e1809.<br/>
    42.	Brouwer JM, et al. Bak core and latch domains separate during activation, and freed core domains form symmetric homodimers. Mol Cell. 2014;55(6):938-46.<br/>
    43.	Czabotar PE, et al. Bax crystal structures reveal how BH3 domains activate Bax and nucleate its oligomerization to induce apoptosis. Cell. 2013;152(3):519-31.<br/>
    44.	Brouwer JM, et al. Conversion of Bim-BH3 from Activator to Inhibitor of Bak through Structure-Based Design. Mol Cell. 2017;68(4):659-72 e9.<br/>
    45.	Birkinshaw RW, et al. Structure of detergent-activated BAK dimers derived from the inert monomer. Mol Cell. 2021.<br/>
    46.	Cowan AD, et al. BAK core dimers bind lipids and can be bridged by them. Nat Struct Mol Biol. 2020;27(11):1024-31.<br/>
    47.	Oltvai ZN, Milliman CL, Korsmeyer SJ. Bcl-2 heterodimerizes in vivo with a conserved homolog, Bax, that accelerates programmed cell death. Cell. 1993;74(4):609-19.<br/>
    48.	Willis SN, et al. Apoptosis initiated when BH3 ligands engage multiple Bcl-2 homologs, not Bax or Bak. Science. 2007;315(5813):856-9.<br/>
    49.	Willis SN, et al. Proapoptotic Bak is sequestered by Mcl-1 and Bcl-xL, but not Bcl-2, until displaced by BH3-only proteins. Genes Dev. 2005;19(11):1294-305.<br/>
    50.	Cheng EH, et al. BCL-2, BCL-X(L) sequester BH3 domain-only molecules preventing BAX- and BAK-mediated mitochondrial apoptosis. Mol Cell. 2001;8(3):705-11.<br/>
    51.	Huang K, et al. BH3-only proteins target BCL-xL/MCL-1, not BAX/BAK, to initiate apoptosis. Cell Res. 2019;29(11):942-52.<br/>
    52.	O'Neill KL, Huang K, Zhang J, Chen Y, Luo X. Inactivation of prosurvival Bcl-2 proteins activates Bax/Bak through the outer mitochondrial membrane. Genes Dev. 2016;30(8):973-88.<br/>
    53.	Cheng TC, Hong C, Akey IV, Yuan S, Akey CW. A near atomic structure of the active human apoptosome. Elife. 2016;5.<br/>
    54.	Li Y, et al. Mechanistic insights into caspase-9 activation by the structure of the apoptosome holoenzyme. Proc Natl Acad Sci U S A. 2017;114(7):1542-7.<br/>
    55.	Marsden VS, et al. Apoptosis initiated by Bcl-2-regulated caspase activation independently of the cytochrome c/Apaf-1/caspase-9 apoptosome. Nature. 2002;419(6907):634-7.<br/>
    56.	Huang W, et al. Mechanistic insights into CED-4-mediated activation of CED-3. Genes Dev. 2013;27(18):2039-48.<br/>
    57.	Tsujimoto Y, Cossman J, Jaffe E, Croce CM. Involvement of the bcl-2 gene in human follicular lymphoma. Science. 1985;228(4706):1440-3.<br/>
    58.	Cleary ML, Smith SD, Sklar J. Cloning and structural analysis of cDNAs for bcl-2 and a hybrid bcl-2/immunoglobulin transcript resulting from the t(14;18) translocation. Cell. 1986;47(1):19-28.<br/>
    59.	Vaux DL, Cory S, Adams JM. Bcl-2 gene promotes haemopoietic cell survival and cooperates with c-myc to immortalize pre-B cells. Nature. 1988;335(6189):440-2.
    60.	Strasser A, Harris AW, Bath ML, Cory S. Novel primitive lymphoid tumours induced in transgenic mice by cooperation between myc and bcl-2. Nature. 1990;348(6299):331-3.
    61.	Hanahan D, Weinberg RA. Hallmarks of cancer: the next generation. Cell. 2011;144(5):646-74.<br/>
    62.	Adams JM, Cory S. The BCL-2 arbiters of apoptosis and their growing role as cancer targets. Cell Death Differ. 2018;25(1):27-36.<br/>
    63.	Cory S, Roberts AW, Colman PM, Adams JM. Targeting BCL-2-like Proteins to Kill Cancer Cells. Trends Cancer. 2016;2(8):443-60.<br/>
    64.	Strasser A, Vaux DL. Cell Death in the Origin and Treatment of Cancer. Mol Cell. 2020;78(6):1045-54.<br/>
    65.	Juin P, Geneste O, Gautier F, Depil S, Campone M. Decoding and unlocking the BCL-2 dependency of cancer cells. Nat Rev Cancer. 2013;13(7):455-65.<br/>
    66.	Oltersdorf T, et al. An inhibitor of Bcl-2 family proteins induces regression of solid tumours. Nature. 2005;435(7042):677-81.<br/>
    67.	Merino D, et al. BH3-Mimetic Drugs: Blazing the Trail for New Cancer Medicines. Cancer Cell. 2018;34(6):879-91.<br/>
    68.	Souers AJ, et al. ABT-199, a potent and selective BCL-2 inhibitor, achieves antitumor activity while sparing platelets. Nat Med. 2013;19(2):202-8.<br/>
    </p>
    </div>
  );
};

export default Pathway;
