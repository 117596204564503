import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import TimeLine from "./TimeLine";
import * as allActions from "../store/actions/times";
import "../Display.css";

const Times = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.times.events);
  const loading = useSelector((state) => state.times.loading);

  useEffect(() => {
    dispatch(allActions.getTimes());
  }, [dispatch]);

  let pageDisplay = "";
  if (loading) {
    pageDisplay = (
      <Loader type="ThreeDots" color="#000000" height={80} width={80} />
    );
  } else {
    pageDisplay = <TimeLine events={events} />;
  }

  return (
    <div className="labCardContainer">
      <h1 className="pageHeading">Timeline of Discovery</h1>
      <p className="pageBlurb">
        Below is a timeline of the major discoveries related to the BCL-2-regulated apoptosis pathway. It primarily focuses on the discovery dates of every protein in the pathway plus some other critical papers that led to our current understanding of how the pathway functions and is regulated. For an expanded overview of the key papers in the field, see our <a href="/classics">Classic Papers</a> page. If you have suggestions for critical discoveries that should be included in this timeline, please contact us at <span className="emailSpan"> contact@bcl2.co</span>.
      </p>
      {pageDisplay}
    </div>
  );
};

export default Times;
