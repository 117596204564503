import React, { useState } from "react";
// import {useSelector, useDispatch} from 'redux'
import { Redirect } from "react-router-dom";
import "../Admin.css";
import axios from "axios";

const CreateBook = () => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [editor, setEditor] = useState("");
  const [image, setImage] = useState("");
  const [amzlink, setAmzlink] = useState("");
  const [blurb, setBlurb] = useState("");
  const [created, setCreated] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const book = {
      title,
      author,
      editor,
      image,
      amzlink,
      blurb,
    };

    axios
      .post("/createBook", { book })
      .then((response) => alert(response.data.msg))
      .then(setCreated(true));
  };

  let pageDisplay = "";
  if (!created) {
    pageDisplay = (
      <div className="formContainer">
        <h1 className="formHeading">Enter Book Details</h1>
        <form onSubmit={handleSubmit} className="form">
          <div className="labelInput">
            <label htmlFor="title" className="formLabel">
              Book Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={title}
              onChange={(evt) => setTitle(evt.target.value)}
              className="formInput"
              placeholder="Title"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="author" className="formLabel">
              Author
            </label>
            <input
              type="text"
              id="author"
              name="author"
              value={author}
              onChange={(evt) => setAuthor(evt.target.value)}
              className="formInput"
              placeholder="Author"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="editor" className="formLabel">
              Editor
            </label>
            <input
              type="text"
              id="editor"
              name="editor"
              value={editor}
              onChange={(evt) => setEditor(evt.target.value)}
              className="formInput"
              placeholder="Editor"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="image" className="formLabel">
              Image
            </label>
            <input
              type="text"
              id="image"
              name="image"
              value={image}
              onChange={(evt) => setImage(evt.target.value)}
              className="formInput"
              placeholder="Image"
            />
          </div>
          <div className="labelInput">
            <label htmlFor="authorpage" className="formLabel">
              Amazon Link
            </label>
            <input
              type="text"
              id="amzlink"
              name="amzlink"
              value={amzlink}
              onChange={(evt) => setAmzlink(evt.target.value)}
              className="formInput"
              placeholder="Amazon Link"
            />
          </div>

          <div className="labelInput">
            <label htmlFor="authorpage" className="formLabel">
              Publisher Blurb
            </label>
            <textarea
              id="blurb"
              name="blurb"
              value={blurb}
              onChange={(evt) => setBlurb(evt.target.value)}
              rows="4"
              cols="50"
              placeholder="Publisher Blurb"
            />
          </div>
          <input className="submitButton" type="submit" value="Submit" />
        </form>
      </div>
    );
  } else {
    pageDisplay = <Redirect to="/" />;
  }

  return pageDisplay;
};

export default CreateBook;
