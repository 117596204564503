import "./Display.css"

const Platform = () => {
    return (
        <div className="platformContainer">
        <h1 className="pageHeading">Website Platform</h1>
        <p>Each page of this website has an underlying database that can be readily created, or updated, using some simple forms to add or edit the details. As such, very little of the site is "hard-coded" with most pages generated as they are loaded using data is automatically downloaded from the database. Accordingly, this serves as a platform that could be adapted for creation of websites based around ANY biological (or potentially other) process or pathways. 
        </p>
        <p>If you have some expertise in any field where users (both experts and novices) would benefit from the types of materials provided by this platform (i.e. a pathway/process overview, timeline, labs, classic papers, resources), and you would be interested in creating a website around this knowledge, please email us at <span className="emailSpan"> contact@bcl2.co</span>. We already have in place some basic strategies for gathering and compiling all of the information to make this process easier and would be happy to share this information and help you build the site.
        </p>      
        <p>We especially would love to expand into other areas of cell death and would ultimately like an entire site dedicated to all cell death mechanisms, but of course there are dozens of pathways where it could be applied in a similar manner. 
        </p>
        </div>
    )
}

export default Platform