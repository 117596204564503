import axios from 'axios'

export const REGISTER = 'REGISTER'
export const LOGIN = 'LOGIN'

export const login = (user) => {
    console.log("lin funct")
    return async (dispatch) => {
    await axios.post("/login", user)
    .then(response => {  
    if (response.data.token) {
        let token = response.data.token
        localStorage.setItem("auth-token", token)
        dispatch({type:LOGIN, username:user.username,token, isLoggedIn: true })    
        } 
    })
    .catch (err => {
        console.log("error" + JSON.stringify(err.msg))
        alert("There was an issue logging in - try again")
        dispatch({type:LOGIN, username: "", isLoggedIn: false })    
        })
    }
}
export const register = (user) => {
    console.log("lin funct")
    return async (dispatch) => {
    await axios.post("/register", user)  
    .then(response => console.log(JSON.stringify(response)))
    dispatch({type: REGISTER, username:user.username, isLoggedIn: true})
}

}