import "../Display.css";
import ResourceDisplay from "../ResourceDisplay";

const SocietiesDisplay = (props) => {
  const societies = props.societies;

  const pageDisplay = societies.map((society, idx) => (
    <div className="resource" key={society.name}>
      <div className="resourceItem">
        <p className="itemTitle">{society.name}</p>
      </div>
      <div className="resourceItem">
        <p>{society.mission}</p>
      </div>
      <div className="resourceItem">
        <a href={society.website} rel="noreferrer" target="_blank">
          Website
        </a>
      </div>
      {idx < societies.length - 1 && <hr className="resourceRuler" />}
    </div>
  ));

  return (
    <div className="resources">
      <h2 className="resourceHeading">Societies</h2>
      <ResourceDisplay>{pageDisplay}</ResourceDisplay>
    </div>
  );
};

export default SocietiesDisplay;
