import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import "../Admin.css";
import "../Display.css";

const Labs = () => {
  const [sortMode, setSortMode] = useState("lab");
  const [labsName, setLabsName] = useState([]);
  const [labsCountry, setLabsCountry] = useState([]);

  useEffect(() => {
    let sortedDataName = [];
    axios
      .get("/getLabs")
      .then((response) => {
        const allData = response.data.labs;
        sortedDataName = allData.sort((a, b) => {
          if (a.headLast < b.headLast) return -1;
          if (a.headLast > b.headLast) return 1;
          return 0;
        });
        setLabsName(sortedDataName);
      })
      .then(() => {
        let arrToSort = [...sortedDataName];
        const sortedDataCountry = arrToSort.sort((a, b) => {
          if (a.country < b.country) return -1;
          if (a.country > b.country) return 1;
          return 0;
        });
        setLabsCountry(sortedDataCountry);
      });
  }, []);

  let pageDisplay = "";
  let mapMode = "";
  if (sortMode === "lab") {
    mapMode = labsName;
  } else {
    mapMode = labsCountry;
  }

  if (labsName.length && labsCountry.length) {
    pageDisplay = mapMode.map((lab, idx) => {
      return (
        <React.Fragment key={`${lab.headLast}${lab.headFirst}`}>
          {sortMode === "country" ? (
            idx === 0 ? (
              <p className="countryLabel">{lab.country}</p>
            ) : mapMode[idx].country !== mapMode[idx - 1].country ? (
              <p className="countryLabel">{lab.country}</p>
            ) : null
          ) : null}

          <div className="labCard">
            <p className="labCardHeading">{`${lab.headFirst} ${lab.headLast}`}</p>
            <div className="labCardElements">
              <p className="labCardLabel">Institute:{` ${lab.institute}`}</p>
              <p className="labCardLabel">Country:{` ${lab.country}`}</p>
              <p className="labCardLabel">
                Research Interests:{` ${lab.focus}`}
              </p>
              <a
                href={lab.url}
                rel="noreferrer"
                target="_blank"
                className="labCardLabel"
              >
                Website
              </a>
            </div>
          </div>
        </React.Fragment>
      );
    });
  } else {
    pageDisplay = (
      <Loader type="ThreeDots" color="#000000" height={80} width={80} />
    );
  }

  return (
    <div className="labsContainer">
      <div className="labCardContainer">
        <h1 className="pageHeading">BCL-2 RESEARCH LABS</h1>
        <p className="pageBlurb">
          Below is a list of many of the major labs doing cell death research
          worldwide. As with the rest of this website, the focus is on those
          labs working on BCL-2 family proteins. If your lab is not listed but
          you want it included, or you know of any that you think we have
          missed, please contact us and will will be happy to consider adding it
          to the list (provided it has a BCL-2 focus). Similarly, if your lab is
          listed, but you do not want it included for any reason, or the details
          we have listed are inaccurate, please let us know at <span className="emailSpan"> contact@bcl2.co</span> and we can
          easily remove or modify as necessary.
        </p>
        <div className="btnContainer">
          <p className="sortLabel">Sort by:</p>
          <button
            onClick={() => setSortMode("lab")}
            style={
              sortMode === "lab"
                ? { backgroundColor: "black", color: "white" }
                : { backgroundColor: "white  ", color: "black" }
            }
            className="labSortBtn"
          >
            PI Name
          </button>
          <button
            onClick={() => setSortMode("country")}
            style={
              sortMode === "country"
                ? { backgroundColor: "black", color: "white" }
                : { backgroundColor: "white", color: "black" }
            }
            className="labSortBtn"
          >
            Country
          </button>
        </div>
        {pageDisplay}
      </div>
    </div>
  );
};

export default Labs;
